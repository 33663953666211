import {defineStore, mapState} from 'pinia';
import {getDatabase, ref as dbRef, query, off} from "firebase/database";
import {MemberStore} from "@/uhas/store/member";
import {
	getAuth,
	onAuthStateChanged,
	signInWithPhoneNumber,
	signInWithCustomToken,
	RecaptchaVerifier,
	signOut
} from "firebase/auth";
import {
	getFunctions,
	httpsCallable
} from "firebase/functions";

const functions = getFunctions();
const auth = getAuth();
const db = getDatabase();

export const AuthStore = defineStore('auth', {

	// persist: {
	// 	paths: ['logged']
	// },

	state: () => ({
		logged: false,
		verifier: null,
  }),

	getters: {
		...mapState(MemberStore, ['user', 'avatar', 'coverImage'])
	},

	actions: {
		signIn (phone, elmID) {
			return new Promise((resolve, reject) => {
				if (!this.verifier) {
					this.verifier = new RecaptchaVerifier(elmID, {
						size: 'invisible',
						callback: () => {
							// reCAPTCHA solved, allow signInWithPhoneNumber.
							// console.log(response)
							signInWithPhoneNumber(auth, phone, this.verifier)
								.then((confirmation) => {
									// SMS sent. Prompt user to type the code from the message, then sign the
									resolve(confirmation.verificationId);
								}).catch(error => {
									reject(error);
								})
						},
						'expired-callback': () => {
							reject({ message: 'Timeout' });
						}
					}, auth);

					this.verifier.verify()
						// .then(p => {
						// 	console.log(p)
						// })
						.catch(error => {
							reject(error);
						});
				} else {
					this.verifier.verify()
						// .then(p => {
						// 	console.log(p)
						// })
						.catch(error => {
							reject(error);
						});
				}

			})
		},

		submitOTP (otp, verificationId, payload) {
			return new Promise((resolve, reject) => {
				httpsCallable(functions, "auth-loginWithPhone")({ otp, verificationId, payload }).then(({ data }) => {
					if (data.error) {
						reject(data);
					} else {
						signInWithCustomToken(auth, data.user.token).then(userCredential => {
							const memberStore = MemberStore();
							memberStore.user = {
								...data.user,
								uid: userCredential.user.uid,
							};
							this.logged = true;

							this.verifier.clear();
							this.verifier = null;

							resolve(data);
						}).catch(err => reject(err))
					}
				}).catch(err => reject(err))
			})
		},

		getCurrentUser () {
			return new Promise((resolve, reject) => {
				const unsubscribe = onAuthStateChanged(getAuth(), user => {
					unsubscribe();
					if (user) {
						const memberStore = MemberStore();
						if (!memberStore.user) {
							this.signOut();
							this.logged = false;

							reject(null);
						} else {
							this.logged = true;

							memberStore.snapUser(user.uid);
						}
					} else {
						this.logged = false;
					}

					resolve(user);
				}, () => {
					this.logged = false;
					reject(null)
				})
			})
		},

		signOut () {
			return new Promise((resolve) => {
				signOut(auth).then(() => {
					off(query(dbRef(db)));

					const memberStore = MemberStore();
					memberStore.user = null;
					this.logged = false;
					resolve();
				});
			})
		}
	}
})
