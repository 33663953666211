<script setup>
import {ref, computed, onMounted} from "vue";
import {useRoute} from "vue-router";
import {AuthStore} from "@/uhas/store/auth";
import {MemberStore} from "@/uhas/store/member";
import {getList} from 'country-list-with-dial-code-and-flag';
import {getAuth, onAuthStateChanged} from "firebase/auth";

import liff from '@line/liff';

import {version} from "../../../package.json";

const authStore = AuthStore()
const memberStore = MemberStore();
const route = useRoute();
const dialCodeList = getList().filter(c => c.code === "TH" || c.code === "LA")
// [
//  { "name": "Afghanistan", "dial_code": "+93", "code": "AF", "flag": "🇦🇫" },
//  { "name": "Myanmar", "dial_code": "+95", "code": "MM", "flag": "🇲🇲" }
// ]

const country = ref("+66");
const phone = ref("")
const otp = ref("")
const verificationId = ref("")
const loading = ref(true)
const otpInput = ref(false)
const otpRequested = ref(false)
const otpRequestAvailable = ref(true)
const logged = ref(-1);
const lineProfile = ref(null);

const messages = ref([]);

/**
 * Computed
 */
const submitFn = computed(() => {
  return otpRequested.value ? submitOTP : signIn
})

/**
 * Mounted
 */
onMounted(() => {
	liff.init({liffId: route.query.liff || "1657800098-xYjzEd7Q"}, () => {
		if (liff.isLoggedIn()) {
			liff.getProfile().then(profile => {
				lineProfile.value = profile;

				const unsubscribe = onAuthStateChanged(getAuth(), user => {
					unsubscribe();
					if (user) {
						memberStore.saveBasicInfo({
							user: {line_token: profile.userId},
						}).then(result => {
							memberStore.user = {
								...memberStore.user,
								...result.user
							};

							// liff.closeWindow();
						}).finally(() => {
							loading.value = false;
							logged.value = 1;
						})
					} else {
						loading.value = false;
						logged.value = 0;
					}
				}, () => {
					loading.value = false;
					logged.value = 0;
				});
			});
		} else {
      liff.login();
    }
	}, err => console.error(err.code, err.message));
});

/**
 * Actions
 */
const signIn = () => {
  let p = phone.value.replaceAll(" ", "");
	if (!p) {
		messages.value.push({severity: 'error', content: 'Invalid phone number'});

		return;
	}

  if (country.value === "+66") {
		if (p.substring(0, 1) === "0" && p.length === 10) {
			p = country.value + p.substring(1);
		} else {
			p = country.value + phone.value;
		}
		if (p.length !== 12) {
			messages.value.push({severity: 'error', content: 'Invalid phone number'});

			return;
		}
  } else {
		p = country.value + phone.value;
	}

	otpRequestAvailable.value = false
	otpRequested.value = false
  loading.value = true
  authStore.signIn(p, 'phone').then(vId => {
		if (vId) {
			verificationId.value = vId
		}
    otpRequested.value = true
		otpInput.value = true
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false

		setTimeout(() => {
			otpRequestAvailable.value = true
		}, 60000)
  })
}

const submitText = computed(() => {
	if (otpRequested.value) {
		if (loading.value) {
			return "Verifying..."
		} else {
			return "Verify"
		}
	} else {
		if (loading.value) {
			return "OTP Sending..."
		} else {
			return "Continue"
		}
	}
})
const submitOTP = () => {
	if (otp.value.length !== 6) {
		messages.value.push({severity: 'error', content: 'Invalid OTP'});

		return;
	}
  loading.value = true
  authStore.submitOTP(otp.value, verificationId.value, {
		line: {userId: lineProfile.value.userId}
	}).then(result => {
		if (result.error) {
			messages.value.push({severity: 'error', content: 'Invalid OTP'});
			console.log(result.error)
		} else {
			logged.value = 1;
			liff.closeWindow();
		}
  }).catch(err => {
		// if (err.error.code === "auth/invalid-verification-code") {
		// 	messages.value = [{severity: 'error', content: 'Invalid OTP'}];
		// }

		messages.value.push({severity: 'error', content: 'Invalid OTP'});
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}
const unsubscribe = () => {
	loading.value = true;
	memberStore.unsubscribeLine()
		.then(() => {
			logged.value = 0;
			authStore.signOut();
		})
		.finally(() => {
			loading.value = false;
			liff.closeWindow();
		})
}
</script>

<style scoped lang="scss">
.login-wrapper {
	display: block;

	.login-panel {
		width: unset;

		img {
			height: 35px !important;
		}
	}
}
.p-float-label, .otp {
	width: 260px;
	max-width: 310px;
	margin-bottom: 15px;

	input {
		width: 100%;
	}
}
.login-form > button {
	width: 100% !important;
}
::v-deep .p-message-wrapper {
	padding-top: 7px;
	padding-bottom: 7px;
}
</style>

<template>
	<div
		v-if="logged === -1"
		class="h-screen w-screen flex justify-content-center align-content-center flex-wrap"
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</div>
	<div
		v-else-if="logged === 1"
		class="h-screen w-screen flex justify-content-center align-content-center flex-wrap"
	>
		<Button
			:loading="loading"
			label="ยกเลิกการเชื่อมต่อ"
			@click="unsubscribe"
		/>
	</div>
	<div
		v-else-if="logged === 0"
		class="login-body"
	>
		<div class="login-wrapper">
			<div class="login-panel">
				<a href="https://uhas.com">
					<img src="@/assets/logo.png" class="logo" alt="UHAS" width="" />
				</a>

				<div class="login-form">
					<h4 class="mb-4">เข้าสู่ระบบ</h4>
					<Message
						v-for="(msg, i) of messages"
						class="w-full"
						:severity="msg.severity"
						:key="`msg-${i}`"
						:life="10000"
						:sticky="false"
					>
						{{msg.content}}
					</Message>
					<span class="p-inputgroup mb-3">
						<Dropdown
							v-model="country"
							:options="dialCodeList"
							:optionLabel="(x) => `${x.flag} ${x.code} (${x.dial_code})`"
							optionValue="dial_code"
							filter
						/>
						<InputMask
							v-model="phone"
							id="phone"
							mask="999999?999999999"
							slotChar=""
							placeholder="PHONE"
						/>
					</span>
					<div
						v-if="otpInput"
						class="otp p-inputgroup w-full">
						<InputMask
							v-model="otp"
							class="mb-0"
							id="otp"
							mask="999999"
							slotChar=""
							placeholder="OTP"
						/>
						<Button
							icon="pi pi-refresh"
							:disabled="!otpRequestAvailable"
							@click="signIn"
						/>
					</div>
					<Button
            :loading="loading"
						:label="submitText"
            @click="submitFn"
					/>
					<span class="text-500 text-sm mt-2">
						Version {{version}}
					</span>
				</div>

				<p></p>
			</div>
		</div>
	</div>
</template>
