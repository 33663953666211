// eslint-disable-next-line no-unused-vars
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCtpEQ8QqtRjCcPM-FIW3iP7cisO_er4xs",
  authDomain: "uhas-com.firebaseapp.com",
  databaseURL: "https://uhas-com-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "uhas-com",
  storageBucket: "cdn.uhas.com",
  messagingSenderId: "182515898632",
  appId: "1:182515898632:web:ca1270e6f38086b050b4bd",
  measurementId: "G-04FZ6R2L10"
};

initializeApp(firebaseConfig);
// connectFunctionsEmulator(getFunctions(), "localhost", 5004);
