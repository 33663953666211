<script setup>
import {ref, computed} from "@vue/reactivity";
import {useToast} from "primevue/usetoast";
import {useRouter} from "vue-router";
import {AuthStore} from "@/uhas/store/auth";
import {getList} from 'country-list-with-dial-code-and-flag';

import {version} from "../../../package.json";

const authStore = AuthStore()
const router = useRouter()
const toast = useToast();

const dialCodeList = getList().filter(c => c.code === "TH" || c.code === "LA")
// [
//  { "name": "Afghanistan", "dial_code": "+93", "code": "AF", "flag": "🇦🇫" },
//  { "name": "Myanmar", "dial_code": "+95", "code": "MM", "flag": "🇲🇲" }
// ]

const country = ref("+66");
const phone = ref("") // 0800000001
const otp = ref("") // 123456
const verificationId = ref("")
// const errMsg = ref("")
const loading = ref(false)
const otpInput = ref(false)
const otpRequested = ref(false)
const otpRequestAvailable = ref(true)

const submitFn = computed(() => {
  return otpRequested.value ? submitOTP : signIn
})

const signIn = () => {
  let p = phone.value.replaceAll(" ", "");
  if (p.substring(0, 1) === "0" && country.value === "+66" && p.length === 10) {
    p = country.value + p.substring(1);
		if (p.length !== 12) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: "Invalid phone number.",
				life: 10000
			});

			return;
		}
  } else {
		p = country.value + phone.value;
	}

	otpRequestAvailable.value = false
	otpRequested.value = false
  loading.value = true
  authStore.signIn(p, 'phone').then(vId => {
		if (vId) {
			verificationId.value = vId
		}
    otpRequested.value = true
		otpInput.value = true
  }).catch(err => {
		let msg = null;
		if (err?.code === "auth/invalid-phone-number") {
			msg = "Invalid phone number.";
		} else if (err?.code) {
			msg = "Code: " + err?.code;
		} else {
			msg = "Something went wrong, Please contact administrator.";
		}

		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
    console.log(err.code || err)
  }).finally(() => {
    loading.value = false

		setTimeout(() => {
			otpRequestAvailable.value = true
		}, 60000)
  })
}

const submitText = computed(() => {
	if (otpRequested.value) {
		if (loading.value) {
			return "Verifying..."
		} else {
			return "Verify"
		}
	} else {
		if (loading.value) {
			return "OTP Sending..."
		} else {
			return "Continue"
		}
	}
})
const submitOTP = () => {
	if (otp.value.length !== 6) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Invalid OTP.",
			life: 10000
		});

		return;
	}
	if (!verificationId.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Something went wrong with verification, Please reload this page and try again.",
			life: 10000
		});

		return;
	}
  loading.value = true
  authStore.submitOTP(otp.value, verificationId.value).then(() => {
    router.replace("/")
  }).catch(err => {
		let msg = null;
		if (err.error?.code === "auth/invalid-verification-code") {
			msg = "Invalid OTP.";
		}	else if (err.error?.code === "auth/code-expired") {
			msg = "OTP expired, Please request new OTP or reload page and try again.";
		} else if (err.error?.code) {
			msg = "Code: " + err.error?.code;
		} else {
			msg = "Something went wrong, Please contact administrator.";
		}

		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
    console.log(err.error || err)
  }).finally(() => {
    loading.value = false
  })
}

const goto = url => location.href = url;
</script>

<style scoped lang="scss">
.p-float-label, .otp {
	width: 260px;
	max-width: 310px;
	margin-bottom: 30px;

	input {
		width: 100%;
	}
}
.login-form > button {
	width: 100% !important;
}
</style>

<template>
	<Toast />

	<div class="login-body">
		<div class="login-wrapper">
			<div class="login-panel">
				<a href="https://uhas.com">
					<img src="@/assets/logo.png" class="logo" alt="UHAS" />
				</a>

				<div class="login-form">
					<h2 class="mb-6">เข้าสู่ระบบ</h2>
					<span class="p-inputgroup mb-5">
						<Dropdown
							v-model="country"
							:options="dialCodeList"
							:optionLabel="(x) => `${x.flag} ${x.code} (${x.dial_code})`"
							optionValue="dial_code"
							filter
						/>
						<!-- <InputNumber
							v-model="phone"
							id="phone"
							placeholder="PHONE"
						/> -->
						<InputMask
							v-model="phone"
							id="phone"
							mask="999999?999999999"
							slotChar=""
							placeholder="PHONE"
						/>
						<!-- <label for="phone">PHONE</label> -->
					</span>
					<form
						v-if="otpInput"
						class="w-full"
						onsubmit="return false"
					>
						<div class="otp p-inputgroup w-full">
							<InputMask
								v-model="otp"
								class="mb-0"
								id="otp"
								mask="999999"
								slotChar=""
								placeholder="OTP"
							/>
							<Button
								icon="pi pi-refresh"
								:disabled="!otpRequestAvailable"
								@click="signIn"
							/>
						</div>
					</form>
					<Button
            :loading="loading"
						:label="submitText"
            @click="submitFn"
					/>
					<span class="text-500 text-sm mt-2">
						Version {{version}}
					</span>
				</div>

				<p>Back to <a href="https://uhas.com">home</a> page.</p>
			</div>
			<div class="login-image text-white">
				<div class="login-image-content">
					<h1>
						<a
							href="https://uhas.com"
							class="text-white"
						>
							Uhas.com
						</a>
					</h1>
					<h2>เราคือเพื่อนแท้นักเทรด</h2>
				</div>
				<div class="image-footer">
					<p>รับชมสาระดีๆ จาก Uhas</p>
					<div class="icons">
						<i
							class="pi pi-youtube"
							@click="goto('https://www.youtube.com/channel/UCb1Xnjo0PgG6ruBPZKfnY0g')"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
