import {createRouter, createWebHistory} from 'vue-router';
import {AuthStore} from "@/uhas/store/auth";

const routes = [
    {
        path: '/dashboard',
				alias: ['/'],
        name: 'dashboard',
        exact: true,
        // component: () => import('./components/Dashboard.vue'),
        component: () => import('@/uhas/pages/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
						requiresAuth: true,
        },
    },
		{
			path: '/calculators',
			name: 'calculators',
			exact: true,
			component: () => import('@/uhas/pages/calculator/Index.vue'),
			children: [
				{
					path: '',
					redirect: '/calculators/lots-calculator',
				},
				{
					path: '/calculators/lots-calculator',
					name: 'lots-calculator',
					exact: true,
					component: () => import('@/uhas/pages/calculator/Lots.vue'),
					// meta: {
					// 		breadcrumb: [{ label: 'Lots Calculator' }],
					// 		requiresAuth: true,
					// },
				},
				{
					path: '/calculators/order-calculator',
					name: 'order-calculator',
					exact: true,
					component: () => import('@/uhas/pages/calculator/Order.vue'),
					// meta: {
					// 		breadcrumb: [{ label: 'Order Calculator' }],
					// 		requiresAuth: true,
					// },
				},
			],
			meta: {
					breadcrumb: [{ label: 'Calculators' }],
					requiresAuth: true,
			},
		},
		{
			path: '/member',
			// alias: ['/'],
			name: 'member',
			exact: true,
			component: () => import('@/uhas/pages/Member.vue'),
			meta: {
					breadcrumb: [{ label: 'Member' }],
					requiresAuth: true,
			},
		},
		{
			path: '/activity',
			// alias: ['/'],
			name: 'activity',
			exact: true,
			component: () => import('@/uhas/pages/Activity.vue'),
			meta: {
					breadcrumb: [{ label: 'Activity' }],
					requiresAuth: true,
			},
		},
		{
			path: '/profile',
			// alias: ['/'],
			name: 'profile',
			exact: true,
			component: () => import('@/uhas/pages/Profile.vue'),
			meta: {
					breadcrumb: [{ label: 'Profile' }],
					requiresAuth: true,
			},
		},
		{
			path: '/courses',
			// alias: ['/'],
			name: 'courses',
			exact: true,
			component: () => import('@/uhas/pages/course/Course.vue'),
			meta: {
					breadcrumb: [{ label: 'Course' }],
					requiresAuth: true,
			},
		},
		{
			path: '/courses/:id/session',
			name: 'course-sessions',
			exact: true,
			component: () => import('@/uhas/pages/course/Session.vue'),
			meta: {
				breadcrumb: [{ label: 'Course detail' }],
				requiresAuth: true,
			},
		},
		{
			path: '/courses/:id/session/:ep',
			name: 'course-episode',
			exact: true,
			component: () => import('@/uhas/pages/course/Episode.vue'),
			meta: {
				breadcrumb: [{ label: 'Session' }],
				requiresAuth: true,
			},
		},
		{
			path: '/free-ea',
			name: 'free-ea',
			exact: true,
			component: () => import('@/uhas/pages/FreeEA.vue'),
			meta: {
				breadcrumb: [{ label: 'Free EA' }],
				requiresAuth: true,
			},
		},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior () {
			return { left: 0, top: 0 };
	}
});

router.beforeEach(async (to, from, next) => {
	const authStore = AuthStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await authStore.getCurrentUser()) {
			next();
    } else {
      next("/login");
    }
  } else if (to.path === '/login' && await authStore.getCurrentUser()) {
		next("/");
	} else {
    next();
  }
});

export default router;
