<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="@/assets/logo.png" alt="UHAS" />
            <span class="app-name">UHAS</span>
        </div>
        <span class="copyright">&#169; UHAS - 2022 (V {{ version }})</span>
    </div>
</template>

<script>
const { version } = require("../package.json");

export default {
    name: "AppFooter",
    data() {
        return {
            version
        }
    },
};
</script>
