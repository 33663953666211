<script setup>
// import {ref} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {AuthStore} from "@/uhas/store/auth";

const authStore = AuthStore();
const router = useRouter();

const { avatar } = storeToRefs(authStore);

// const mobileMenu = ref([
// 	{icon: 'pi pi-align-left', value: 'Left'},
// 	{icon: 'pi pi-align-right', value: 'Right'},
// 	{icon: 'pi pi-align-center', value: 'Center'},
// 	{icon: 'pi pi-align-justify', value: 'Justify'}
// ]);

const handleSignOut = () => {
	authStore.signOut().then(() => {
    router.replace("/login");
	})
};

</script>
<style lang="scss">
@media screen and (max-width: 1091px) {
	.menu-button {
		display: none !important;
	}
	.layout-wrapper .layout-content-wrapper {
		padding-bottom: 54px !important;
	}
}

.mobile-menu {
	@media screen and (min-width: 1092px) {
		display: none !important;
	}
	height: auto !important;

	.p-sidebar-header {
		display: none;
	}
	.p-sidebar-content {
		padding: 0 !important;

		a {
			&.router-link-active {
				background: var(--primary-color);
				color: #fff;
			}
			& > i {
				font-size: 1.7rem;
			}
		}

		.p-togglebutton {
			color: var(--primary-color);

			.p-button-icon {
				font-size: 1.7rem;
				color: var(--primary-color);
			}
		}
	}
}

</style>

<template>
    <div class="layout-topbar">
				<Sidebar
					class="mobile-menu"
					position="bottom"
					:visible="true"
					:modal="false"
					:showCloseIcon="false"
					:dismissable="false"
					:baseZIndex="1110"
				>
					<div class="flex">
						<template v-for="(menu, i) in menu[0].items" :key="`menu-${i}`">
							<MegaMenu
								v-if="menu.items && menu.items.length > 0"
								:model="menu.items"
								class="hidden xl:block"
							/>
							<!-- <SplitButton
								v-if="menu.items && menu.items.length > 0"
								:model="menu.items"
								icon="pi pi-plus"
								class="bg-primary border-round">
									<i :class="menu.icon"></i>
									<span class="mr-2 flex align-items-center font-bold">{{menu.label}}</span>
							</SplitButton> -->
							<template  v-if="menu.items && menu.items.length > 0">
								<TieredMenu
									:ref="`subMenu[${i}]`"
									:id="`overlay_tmenu-${i}`"
									:model="menu.items"
									class="border-noround"
									popup
								/>
								<ToggleButton
									aria-haspopup="true"
									:aria-controls="`overlay_tmenu-${i}`"
									:onIcon="menu.icon"
									:offIcon="menu.icon"
									:onLabel="menu.label"
									:offLabel="menu.label"
									class="border-none flex-1 flex flex-column align-items-center justify-content-evenly pt-2 pb-1"
									@click="toggle($event, i)"
								>
									<!-- <i :class="menu.icon"></i> -->
									<!-- <div>{{menu.label}}</div> -->
								</ToggleButton>
							</template>
							<router-link
								v-else
								:to="menu.to"
								class="flex-1 flex flex-column align-items-center justify-content-evenly pt-2 pb-1"
							>
								<i :class="menu.icon"></i>
								<div>{{menu.label}}</div>
							</router-link>
						</template>
					</div>
				</Sidebar>
        <div class="topbar-left">
            <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
							<i class="pi pi-bars"></i>
            </a>

            <router-link to="/" class="horizontal-logo">
							<img id="logo-horizontal" class="horizontal-logo" src="layout/images/logo-white.svg" alt="diamond-layout" />
            </router-link>

            <span class="topbar-separator"></span>

            <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
							<template v-if="$route.meta.breadcrumb">
								<span>{{$route.meta.breadcrumb[0].label}}</span>
							</template>
            </div>

            <img id="logo-mobile" class="mobile-logo" src="@/assets/logo.png" alt="diamond-layout" />
        </div>

        <AppMenu
					:model="menu"
					:layoutMode="layoutMode"
					:active="menuActive"
					:mobileMenuActive="staticMenuMobileActive"
          @menu-click="onMenuClick"
					@menuitem-click="onMenuItemClick"
					@root-menuitem-click="onRootMenuItemClick"
				/>

        <div class="layout-mask modal-in"></div>

        <div class="topbar-right">
            <ul class="topbar-menu">
							<li>{{!authStore.user?.uhas_uid ? "" : `รหัสสมาชิก: ${authStore.user?.uhas_uid}`}}</li>
							<!-- <li class="search-item">
									<a tabindex="0" @click="onSearchClick">
											<i class="pi pi-search"></i>
									</a>
							</li>
							<li class="notifications-item" :class="{ 'active-menuitem ': topbarNotificationMenuActive }">
									<a href="#" tabindex="0" @click="onTopbarNotificationMenuButtonClick">
											<i class="pi pi-bell"></i>
											<span class="topbar-badge">5</span>
									</a>
									<ul class="notifications-menu fade-in-up">
											<li role="menuitem">
													<a href="#" tabindex="0">
															<i class="pi pi-shopping-cart"></i>
															<div class="notification-item">
																	<div class="notification-summary">New Order</div>
																	<div class="notification-detail">You have <strong>3</strong> new orders.</div>
															</div>
													</a>
											</li>
											<li role="menuitem">
													<a href="#" tabindex="0">
															<i class="pi pi-check-square"></i>
															<div class="notification-item">
																	<div class="notification-summary">Withdrawn Completed</div>
																	<div class="notification-detail">Funds are on their way.</div>
															</div>
													</a>
											</li>
											<li role="menuitem">
													<a href="#" tabindex="0">
															<i class="pi pi-chart-line"></i>
															<div class="notification-item">
																	<div class="notification-summary">Monthly Reports</div>
																	<div class="notification-detail">New reports are ready.</div>
															</div>
													</a>
											</li>
											<li role="menuitem">
													<a href="#" tabindex="0">
															<i class="pi pi-comments"></i>
															<div class="notification-item">
																	<div class="notification-summary">Comments</div>
																	<div class="notification-detail"><strong>2</strong> new comments.</div>
															</div>
													</a>
											</li>
											<li role="menuitem">
													<a href="#" tabindex="0">
															<i class="pi pi-exclamation-circle"></i>
															<div class="notification-item">
																	<div class="notification-summary">Chargeback Request</div>
																	<div class="notification-detail"><strong>1</strong> to review.</div>
															</div>
													</a>
											</li>
									</ul>
							</li> -->

							<li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
									<a href="#" @click="onTopbarUserMenuButtonClick">
											<img :src="avatar" alt="diamond-layout" class="profile-image" />
											<span class="profile-name">
												{{ authStore.user?.firstName || authStore.user?.phone || '???' }}
											</span>
									</a>
									<ul class="profile-menu fade-in-up">
											<li>
													<router-link to="/profile">
															<i class="pi pi-user"></i>
															<span>Profile</span>
													</router-link>
											</li>
											<li>
													<a
														href="#"
														@click="handleSignOut"
													>
															<i class="pi pi-power-off"></i>
															<span>Logout</span>
													</a>
											</li>
									</ul>
							</li>

							<!-- <li class="right-sidebar-item">
									<a href="#" tabindex="0" @click="onRightMenuClick">
											<i class="pi pi-align-right"></i>
									</a>
							</li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import AppMenu from "./AppMenu";
export default {
    name: "AppTopbar",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "search-click", "topbar-notification", "topbar-user-menu", "right-menubutton-click"],
    props: {
        topbarNotificationMenuActive: Boolean,
        topbarUserMenuActive: Boolean,
        layoutMode: String,
        menu: Array,
        menuActive: Boolean,
        staticMenuMobileActive: Boolean
    },
    data() {
        return {
            items: [],
						mobileMenu: [
							{icon: 'pi pi-align-left', value: 'Left'},
							{icon: 'pi pi-align-right', value: 'Right'},
							{icon: 'pi pi-align-center', value: 'Center'},
							{icon: 'pi pi-align-justify', value: 'Justify'}
						],
						subMenu: []
        };
    },
    components: {
        AppMenu
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
        onRootMenuItemClick(event) {
            this.$emit("root-menuitem-click", event);
        },
        onMenuButtonClick(event) {
            this.$emit("menu-button-click", event);
        },
        onSearchClick(event) {
            this.$emit("search-click", event);
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.$emit("topbar-notification", event);
        },
        onTopbarUserMenuButtonClick(event) {
            this.$emit("topbar-user-menu", event);
        },
        onRightMenuClick(event) {
            this.$emit("right-menubutton-click", event);
        },
        isMobile() {
            return window.innerWidth <= 1091;
        },
				toggle (event, i) {
					console.log(this.$refs[`subMenu[${i}]`])
					this.$refs[`subMenu[${i}]`][0].toggle(event)
				}
    }
};
</script>
